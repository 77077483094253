<template>
  <div class="horizontalVideo_box">
    <div class="phead-left" @click.stop="$router.go(-1)">
      <svg-icon icon-class="headArrow" />
    </div>
    <Loading v-if="isLoading" />
    <div class="horizontalVideo_warp" v-else>
      <div class="h_video">
        <div class="videoAdv" v-if="timer > 0 && videoInfo.watch && videoInfo.watch.isFreeWatch">
          <AdvVideoSwiper :advList="advsList" v-if="advsList.length" :hasPagination="false" class="adv" />
          <div class="timer_close">{{ timer }} 秒后关闭</div>
        </div>
        <HorIzontalScreen v-else-if="videoInfo.id" :videoInfo="videoInfo" :key="videoInfo.id" />
      </div>
      <div class="main overflow-y-auto">
        <div class="videoInfo flex-center-between">
          <div class="title">{{ videoInfo.title }}</div>
          <div class="zanBox flex-center-center">
            <div class="zanIcon" @click="collecte">
              <svg-icon v-if="!videoInfo.vidStatus.hasCollected" icon-class="zan1" class="zanIcon" />
              <svg-icon v-else icon-class="zaned1" class="zanIcon" />
            </div>
            <span>{{ videoInfo.likeCount | watchCountW }}</span>
          </div>
        </div>
        <div class="tagBox">
          <div class="tag" v-for="item in videoInfo.tags" :key="item.id" @click="openTag(item)">#{{ item.name }}#</div>
        </div>
        <!-- 广告轮播 -->
        <AdvSwiper :advList="advList" class="advSwiper" v-if="advList.length" />
        <div class="subTitle">猜你喜欢</div>
        <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad" @onRefresh="onRefresh" :isNoData="isNoData">
          <div class="moreMoviesBox">
            <VideoBox @click.native="openVideo(item)" class="movieBox" :videoInfo="item" v-for="item in list" :key="item.id" />
          </div>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import Loading from '@/components/Loading';
import { getVidInfo, queryCollection } from '@/api/video';
import { careCollectVideo } from '@/api/user';
import { AdType, getAdItem } from '@/utils/getConfig';
import AdvVideoSwiper from '@/components/AdvVideoSwiper';
import AdvSwiper from '@/components/AdvSwiper';
import HorIzontalScreen from '@/components/Video/HorIzontalScreen';
import PullRefresh from '@/components/PullRefresh';
import VideoBox from '@/components/VideoBox';

export default {
  components: {
    AdvVideoSwiper,
    HorIzontalScreen,
    Loading,
    AdvSwiper,
    PullRefresh,
    VideoBox,
  },
  data() {
    return {
      isLoading: true,
      videoInfo: {
        publisher: {},
        vidStatus: {},
      }, //视频信息
      advsList: [],
      timer: 0,
      advList: [], // 广告列表
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.countdown();
    this.getVideoInfo();
    this.advsList = getAdItem(AdType.FREE_VIDEO);
    this.advList = getAdItem(AdType.DETAILS_ADV); // 获取轮播广告数据
  },
  methods: {
    // 倒计时
    countdown() {
      if (this.advsList.length == 0) return;
      this.timer = this.advsList[0]?.duration > 0 ? this.advsList[0]?.duration : 15;
      let countdown = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(countdown);
        }
      }, 1000);
    },
    async getVideoInfo() {
      try {
        let req = {
          videoID: this.$route.query.id,
          sectionId: this.$route.query.sectionId || undefined,
        };
        let res = await this.$Api(getVidInfo, req);
        if (res.code == 200) {
          this.videoInfo = res.data;
          this.getList();
        }
      } finally {
        this.isLoading = false;
      }
    },
    //收藏
    async collecte() {
      let isCollect = this.videoInfo?.vidStatus?.hasCollected;
      let id = this.videoInfo?.id;
      let req = {
        type: 'movie',
        objID: id,
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.videoInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast('取消点赞');
            this.videoInfo.likeCount--;
          } else {
            Toast('点赞成功');
            this.videoInfo.likeCount++;
          }
          return;
        }
        Toast('操作失败');
      } catch (e) {
        Toast('操作失败');
      }
    },
    // 查询列表
    async getList(type) {
      let req = {
        uid: this.videoInfo.publisher.uid,
        pageSize: String(this.pageSize),
        pageNumber: String(this.pageNumber),
        type: 'new',
        newsType: 'MOVIE',
      };
      try {
        let res = await this.$Api(queryCollection, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    openVideo(item) {
      if (item.playTime > 300) {
        // let routeObj = {
        //   path: '/horizontalVideo',
        //   query: {
        //     id: item.id,
        //     sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
        //   },
        // };
        // if (this.fromPath) {
        //   routeObj.query.fromPath = this.fromPath;
        // }
        // this.$router.replace(routeObj);
        this.videoInfo = item;
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
    //前往标签页
    openTag(item) {
      this.$router.push({ path: '/tagVideo', query: { id: item.id, name: item.name, type: 'MOVIE' } });
    },
  },
};
</script>
<style scoped lang="scss">
// /deep/ .videoBox .coverBox {
//   height: 188px;
// }
.horizontalVideo_box {
  height: 100%;
  .phead-left {
    position: absolute;
    top: 12.5px;
    left: 25px;
    z-index: 3001;
    pointer-events: auto;
    svg {
      height: 19px;
      width: 19px;
    }
  }
}
.horizontalVideo_warp {
  height: 100%;

  .h_video {
    width: 100%;
    height: 210px;
    background: #000000;
    .videoAdv {
      position: relative;
      height: 210px;
      color: #ffffff;
      .timer_close {
        width: 100px;
        font-size: 12px;
        text-align: center;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        background: #00000070;
        padding: 4px 0;
        border-radius: 40px;
      }
      .adv {
        height: 100%;
        /deep/ .swiper-container {
          height: 210px;
        }
        /deep/ img {
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
  .main {
    padding: 10px 20px 0;
    height: calc(100vh - 211px);
    .videoInfo {
      margin-bottom: 16px;
      .title {
        font-size: 14px;
        font-weight: 600;
        color: rgb(121, 121, 121);
        width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .zanBox {
        .zanIcon {
          height: 24px;
          width: 24px;
          margin-right: 6px;
        }
        span {
          font-size: 12px;
          color: rgb(121, 121, 121);
          letter-spacing: 0.6px;
        }
      }
    }
    .tagBox {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      letter-spacing: 0.2px;
      .tag {
        margin: 0 15px 16px 0;
      }
      .tag:nth-child(1n) {
        color: rgb(232, 100, 161);
      }
      .tag:nth-child(2n) {
        color: rgb(219, 141, 84);
      }
      .tag:nth-child(3n) {
        color: rgb(168, 218, 255);
      }
      .tag:last-child {
        margin-right: unset;
      }
    }
    .advSwiper {
      height: 93px;
      width: 335px;
      margin-bottom: 19px;
      /deep/ .adv-container {
        overflow: hidden;
        border-radius: 6px;
        height: 93px;
        transform: translateZ(0px);
        .my-swipe {
          height: 93px;
          .adv-swiper-slide {
            height: 93px !important;
            width: 335px !important;
          }
        }
        img {
          height: 100%;
        }
      }
      /deep/ img {
        height: 100%;
        object-fit: fill;
      }
    }
    .subTitle {
      font-size: 14px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      margin-bottom: 10px;
    }
    .moreMoviesBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 9px;
      grid-column-gap: 7px;
      .movieBox {
        width: 164px;
      }
    }
  }
}
</style>
